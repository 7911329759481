<template>
    <div class='commonTop'>
        <div class="left">
            <span>请按条件查询：</span>
             <el-radio-group 
             v-model="initType"  
             @change="choseType"
             size="small">
                <el-radio-button 
                :label="v.label" 
                v-for="(v,i) in arr"
                :key="i">
                    {{v.name}}
                </el-radio-button>
            </el-radio-group>
            <span class="position" @click="getpositionTip">
                <i class="iconfont icon-shuoming"></i>
                岗位说明
            </span>
            <slot name="exportBtn"></slot>
        </div>
        <el-dialog
            title="岗位说明"
            :visible.sync="positionModel"
            width="38%"
            >
            <div class="positionModel">   
                <div>
                    <span>所有岗位(19)：</span>.Net开发程序员、Java开发程序员、Android开发程序员、web前端工程师、
                    H5程序员、Python程序员、实施工程师、测试工程师、IT运维支持工程师、数据库程序员、技术支持、运维工程师、
                    技术文职、文档工程师、项目助理、网络运维、手机测试、软件销售、其他
                </div>
                 <div>
                    <span>开发岗(6)：</span>.Net开发程序员、Java开发程序员、Android开发程序员、web前端工程师、
                    H5程序员、Python程序员
                </div> 
                <div>
                    <span>技术岗(12)：</span>实施工程师、测试工程师、IT运维支持工程师、数据库程序员、技术支持、运维工程师、
                    技术文职、文档工程师、项目助理、网络运维、手机测试、软件销售
                </div>
            </div>         
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            positionModel:false,
            initType:'0',   
            arr:[
                {
                    name:'全部',
                    label:0
                },
                {
                    name:'开发岗',
                    label:1
                },
                {
                    name:'技术岗',
                    label:2
                },
                {
                    name:'其他',
                    label:3
                }
            ]
        }
    },
    methods:{
        getpositionTip(){
            this.positionModel=!this.positionModel
        },
        choseType(value){
            this.$emit('choseType',value)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/color.scss';
.commonTop{
    margin: 20px 0px;
}
.left{
    font-size: 14px;
    position: relative;
    span{
        margin-right:10px
    }
    .tip{
        font-size: 12px;
        margin-left: 10px;
        color: #909090;
    }
    .position{
        position: absolute;
        right: 0px;
        top: 0px;
        display: inline-block;
        width: 82px;
        @include display-flex;
        padding: 6px;
        background-color: #f2f6fc;
        border-right: 10px;
        font-size: 13px;
        cursor: pointer;
        vertical-align: middle;
        i{
            margin-right: 3px;
        }
    }
}
.positionModel {
    max-height: 500px;
    overflow: auto;
    background-color: $bgColor;
    padding: 6px;
    div{
        line-height: 26px;
        margin-bottom: 10px;
        span{
            color: red;
        }
    }
}
</style>