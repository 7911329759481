<template>
    <div>
        <el-page-header @back="goBack" content="班级统计" >
        </el-page-header>
        <commonTop  @choseType="choseType">
            <div slot='exportBtn' class="export">
                <commonExport :exportUrl='exportUrl'></commonExport>
            </div> 
        </commonTop> 
        <tm-table v-bind:columns="columns" :dataSource="tableData" :hasIndex="true">
        </tm-table>
    </div>
</template>
<script>
import commonTop from '../../components/commonTop'
export default {
    components:{
        commonTop
    },
    data() {
        return {
            tableData:[],
            orgId:null,
            columns:[
                {
                    hasSort:false,
                    prop:'className',
                    label:'班级名称'
                },
                {
                    hasSort:true,
                    prop:'deliveryNum',
                    label:'总投递量'
                },
                {
                    hasSort:true,
                    prop:'perCapitaNum',
                    label:'人均投递量'
                },
                {
                    hasSort:true,
                    prop:'resumePassNum',
                    label:'简历通过量'
                },
                {
                    hasSort:false,
                    prop:'resumePassPoint',
                    label:'简历通过率'
                },
                {
                    hasSort:true,
                    prop:'interviewNum',
                    label:'实际面试量'
                },
                {
                    hasSort:false,
                    prop:'toInterviewPoint',
                    label:'实际赴约率'
                },
                {
                    hasSort:true,
                    prop:'passNum',
                    label:'面试通过量'
                },
                {
                    hasSort:false,
                    prop:'passPoint',
                    label:'面试通过率'
                },
            ],
            exportUrl:''
        }
    },
    methods: {
        goBack(){
            this.$router.push({path:'/dbShoolStatic'})
        },
        choseType(type){
            this.getInitList(type)
        },
        getInitList(type){
            this.exportUrl=`/tea/class/statistics/export?type=${type}&orgId=${this.orgId}`
            let url=`/tea/class/statistics?type=${type}&orgId=${this.orgId}`  
            this.$htp.get_(url).then((res)=>{
                // this.tableData=res.data
                res.data.forEach(e=>{
                    e.resumePassPoint=e.resumePassPoint+'%'
                    e.toInterviewPoint=e.toInterviewPoint+'%'
                    e.passPoint=e.passPoint+'%'
                })
                this.tableData=res.data
            }) 
        },
    },
    mounted(){
        this.orgId=this.$route.query.id
        this.getInitList(0)
    }
}
</script>
<style lang="scss" scoped>
.export{
    position:absolute;
    top: 0px;
    right: 60px;
}
</style>